@font-face {
    font-family: "natura11y-icons";
    src: url("./natura11y-icons.ttf?b45c7d458201d376b99e13f77318c5bc") format("truetype"),
url("./natura11y-icons.woff2?b45c7d458201d376b99e13f77318c5bc") format("woff2"),
url("./natura11y-icons.woff?b45c7d458201d376b99e13f77318c5bc") format("woff");
}

.icon:before {
    font-family: natura11y-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon.icon-accessibility:before {
    content: "\f101";
}
.icon.icon-arrow-down:before {
    content: "\f102";
}
.icon.icon-arrow-left:before {
    content: "\f103";
}
.icon.icon-arrow-right:before {
    content: "\f104";
}
.icon.icon-arrow-up:before {
    content: "\f105";
}
.icon.icon-bell:before {
    content: "\f106";
}
.icon.icon-calendar:before {
    content: "\f107";
}
.icon.icon-caret-down:before {
    content: "\f108";
}
.icon.icon-caret-left:before {
    content: "\f109";
}
.icon.icon-caret-right:before {
    content: "\f10a";
}
.icon.icon-caret-up:before {
    content: "\f10b";
}
.icon.icon-checkbox-checked:before {
    content: "\f10c";
}
.icon.icon-checkbox-unchecked:before {
    content: "\f10d";
}
.icon.icon-chevron-down:before {
    content: "\f10e";
}
.icon.icon-chevron-left:before {
    content: "\f10f";
}
.icon.icon-chevron-right:before {
    content: "\f110";
}
.icon.icon-chevron-up:before {
    content: "\f111";
}
.icon.icon-close:before {
    content: "\f112";
}
.icon.icon-confirm:before {
    content: "\f113";
}
.icon.icon-delete:before {
    content: "\f114";
}
.icon.icon-disabled:before {
    content: "\f115";
}
.icon.icon-double-chevron-down:before {
    content: "\f116";
}
.icon.icon-double-chevron-left:before {
    content: "\f117";
}
.icon.icon-double-chevron-right:before {
    content: "\f118";
}
.icon.icon-double-chevron-up:before {
    content: "\f119";
}
.icon.icon-edit:before {
    content: "\f11a";
}
.icon.icon-facebook:before {
    content: "\f11b";
}
.icon.icon-file-download:before {
    content: "\f148";
}
.icon.icon-file-upload:before {
    content: "\f149";
}
.icon.icon-github:before {
    content: "\f11c";
}
.icon.icon-grid:before {
    content: "\f11d";
}
.icon.icon-hashtag:before {
    content: "\f11e";
}
.icon.icon-heart:before {
    content: "\f11f";
}
.icon.icon-home:before {
    content: "\f120";
}
.icon.icon-info:before {
    content: "\f121";
}
.icon.icon-instagram:before {
    content: "\f122";
}
.icon.icon-language:before {
    content: "\f123";
}
.icon.icon-lightbulb:before {
    content: "\f147";
}
.icon.icon-linkedin:before {
    content: "\f124";
}
.icon.icon-list:before {
    content: "\f125";
}
.icon.icon-loading:before {
    content: "\f126";
}
.icon.icon-mail:before {
    content: "\f127";
}
.icon.icon-map-pin:before {
    content: "\f128";
}
.icon.icon-menu:before {
    content: "\f129";
}
.icon.icon-minus:before {
    content: "\f12a";
}
.icon.icon-mobile:before {
    content: "\f12b";
}
.icon.icon-more-horizontal:before {
    content: "\f12c";
}
.icon.icon-more-vertical:before {
    content: "\f12d";
}
.icon.icon-npm:before {
    content: "\f12e";
}
.icon.icon-page-end:before {
    content: "\f12f";
}
.icon.icon-page-start:before {
    content: "\f130";
}
.icon.icon-parking:before {
    content: "\f131";
}
.icon.icon-people:before {
    content: "\f132";
}
.icon.icon-person:before {
    content: "\f133";
}
.icon.icon-plus:before {
    content: "\f134";
}
.icon.icon-print:before {
    content: "\f135";
}
.icon.icon-question-mark:before {
    content: "\f136";
}
.icon.icon-radio-checked:before {
    content: "\f137";
}
.icon.icon-radio-unchecked:before {
    content: "\f138";
}
.icon.icon-search:before {
    content: "\f139";
}
.icon.icon-settings:before {
    content: "\f13a";
}
.icon.icon-shopping-cart:before {
    content: "\f13b";
}
.icon.icon-shrink:before {
    content: "\f13c";
}
.icon.icon-star-fill:before {
    content: "\f13e";
}
.icon.icon-switch-off:before {
    content: "\f13f";
}
.icon.icon-switch-on:before {
    content: "\f140";
}
.icon.icon-twitter:before {
    content: "\f141";
}
.icon.icon-upload:before {
    content: "\f142";
}
.icon.icon-warn:before {
    content: "\f143";
}
.icon.icon-wheelchair:before {
    content: "\f144";
}
.icon.icon-youtube:before {
    content: "\f145";
}
.icon.icon-bookmark:before {
    content: "\f146";
}
.icon.icon-asterisk:before {
    content: "\f14a";
}
.icon.icon-recycle:before {
    content: "\f14b";
}
.icon.icon-mode-light-dark:before {
    content: "\f14c";
}
.icon.icon-globe:before {
    content: "\f14d";
}
.icon.icon-star:before {
    content: "\f14e";
}
.icon.icon-tiktok:before {
    content: "\f14f";
}
.icon.icon-open-new:before {
    content: "\f150";
}
.icon.icon-menu-label-open:before {
    content: "\f151";
}
.icon.icon-search-label-open:before {
    content: "\f152";
}
.icon.icon-bookmark-add:before {
    content: "\f153";
}
.icon.icon-bookmark-remove:before {
    content: "\f154";
}
.icon.icon-moon:before {
    content: "\f155";
}
.icon.icon-question:before {
    content: "\f156";
}
.icon.icon-share:before {
    content: "\f157";
}
.icon.icon-star-outline:before {
    content: "\f158";
}
.icon.icon-sun:before {
    content: "\f159";
}
.icon.icon-expand:before {
    content: "\f13d";
}
.icon.icon-location:before {
    content: "\f15a";
}
.icon.icon-plane:before {
    content: "\f15b";
}
.icon.icon-car:before {
    content: "\f15c";
}
.icon.icon-sign-in:before {
    content: "\f15d";
}
.icon.icon-sign-out:before {
    content: "\f15e";
}
.icon.icon-clear:before {
    content: "\f15f";
}
.icon.icon-zoom-in:before {
    content: "\f160";
}
.icon.icon-zoom-out:before {
    content: "\f161";
}
.icon.icon-pause:before {
    content: "\f162";
}
.icon.icon-play:before {
    content: "\f163";
}
.icon.icon-twitter-x:before {
    content: "\f164";
}
.icon.icon-volume-mute:before {
    content: "\f165";
}
.icon.icon-volume:before {
    content: "\f166";
}
.icon.icon-play-circle:before {
    content: "\f167";
}
.icon.icon-pause-circle:before {
    content: "\f168";
}
.icon.icon-lock:before {
    content: "\f169";
}
.icon.icon-figma:before {
    content: "\f16a";
}
.icon.icon-call:before {
    content: "\f16b";
}
